/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_w045gNRdq",
    "aws_user_pools_web_client_id": "2k6el131dd89jfccof3npltll",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "msg01",
            "endpoint": "https://znr2fgbxmk.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "devicepull01",
            "endpoint": "https://o9bogofxld.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "admwa01dev01upload01",
            "endpoint": "https://ni832reodf.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;